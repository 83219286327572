import React from "react";
import loadable from "@loadable/component";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import BHero from "../../components/Hero/b-hero";
import {
  fineDiningHero,
  fineDiningSubHero,
  fineDiningWhiteGlove,
  fineDiningLargeFeatureA,
  fineDiningTrusted,
  fineDiningMultiSection,
  fineDiningCTA,
  fineDiningVideo,
  fineDiningTestimonials,
  fineDiningCar,
} from "../../data/fine-dining-data";
import {
  bardoAndVanaSlides,
  heroSuccess,
} from "../../data/success-stories-data";

import heroBg from "../../images/hero-images/fine-dining-hero.png";
import whiteGloveImport from "../../images/white-glove/restaurant-single-white-glove.png";

const NoVisualsCustom = loadable(() =>
  import("../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/FineDiningLF")
);
const TrustedNumbers = loadable(() =>
  import("../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../components/SuccessStories/SuccessStories")
);
const VideoSection = loadable(() =>
  import("../../components/VideoSection/VideoSection")
);
const TestmonialReviews = loadable(() =>
  import("../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../components/BusinessTypes/BusinessTypes")
);
// const CapterraBanner = loadable(() =>
//   import("../../components-v2/CapterraBanner")
// );
const TopRestaurantsLogos = loadable(() =>
  import("../../components-v2/TopRestaurantsLogos")
);

const partnersTitle = "Used by these popular restaurants and bars:";

const FineDining = () => {
  return (
    <Layout>
      <SEO
        title="Fine Dining POS System |Point of Sale System for Fine Dining | SpotOn"
        description="SpotOn POS has many features for fine dining, including handheld devices to eliminate wait times, custom menu build, real-time reporting, and more."
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/fine-dining-pos"
        productSchemaSR
      />
      <BHero sectionData={fineDiningHero} heroBg="fine-dining-hero.png" />
      <NoVisualsCustom sectionData={fineDiningSubHero} simple />
      <WhiteGlove
        sectionData={fineDiningWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={fineDiningLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={fineDiningTrusted} />
      <LargeFeatures
        sectionData={fineDiningMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      {/* <CapterraBanner
        className="mt-16 lg:mt-20 mb-20 lg:mb-40"
        imageName="capterra-restaurant-pos.png"
        title={`The POS with the <span class="text-primary">best</span> customer support, according to restaurateurs<span class="text-primary">.</span>`}
        withCta
        ctaLabel="See the results"
        ctaTarget="https://datainsights.capterra.com/p/restaurant-pos/226928/spoton/references?g=na&c=&c=&c=&sc=6321593"
      /> */}
      <LargeCta sectionData={fineDiningCTA} />
      <SuccessStories sectionData={heroSuccess} slides={bardoAndVanaSlides} />
      <VideoSection sectionData={fineDiningVideo} />
      <TopRestaurantsLogos sectionTitle={partnersTitle} />
      <TestmonialReviews sectionData={fineDiningTestimonials} />
      <BusinessTypes sectionData={fineDiningCar} />
    </Layout>
  );
};

export default FineDining;
