// eslint-disable-next-line import/prefer-default-export
export const fineDiningHero = {
  title: "Fine dining POS system for restaurateurs",
  subtext:
    "Provide guests with an exemplary experience and streamline back-of-house operations at your fine dining establishment with SpotOn.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/fine-dining-pos/demo",
  },
};
export const fineDiningSubHero = {
  title: "Adapt and serve up a world-class experience",
  subtext:
    "Running a fine dining restaurant presents many challenges—delivering a quality guest experience, controlling costs, and managing profit margins, just to name a few. We’re here to help. SpotOn works with you to develop tailor-made technology solutions to keep you competitive and keep your guests satisfied.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/fine-dining-pos/demo",
  },
};

export const fineDiningWhiteGlove = {
  mainTitle: "Here for you—anytime",
  title: "White-glove service and support",
  content:
    "You have a lot on your plate. Let SpotOn lighten your load. Our team will work closely with you to customize the software and point-of-sale technology you need, provide in-person installation and training, and continue to be there anytime you need us with 24/7 support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/fine-dining-pos/demo",
  },
};

export const fineDiningLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "The custom-crafted solution your restaurant deserves",
      blockSubTitle:
        "Maximize sales and make life easier for your staff with an end-to-end solution that is far more than your ordinary POS. With reservations & waitlisting, elite reporting capabilities, remote management, flexible configuration features, integrated online ordering functionality, and more, SpotOn can help you take your restaurant to the next level.",
      blockImg: "olo-modified.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/fine-dining-pos/demo",
      },
    },
  ],
};

export const fineDiningTrusted = [
  {
    title: "67%",
    subtext: "Increase in repeat visits with online ordering",
  },
  {
    title: "$800+",
    subtext:
      "Monthly savings over reservation platforms that charge per-cover fees",
  },
  {
    title: "$7,500+",
    subtext: "Monthly savings compared to third-party delivery apps",
  },
];

export const fineDiningMultiSection = {
  sectionTitle: "Tailor-made products for fine dining",
  featureBlocks: [
    {
      blockTitle: "SpotOn Restaurant",
      blockSubTitle:
        "Get a restaurant point-of-sale system designed to help your staff work more efficiently so you can focus on what’s most important—creating an amazing experience for your guests.",
      blockList: [
        "Custom menu build & table layouts",
        "Lightning-fast ordering & payments",
        "Handheld POS with contactless payments",
        "Real-time updates & reporting",
        "Remote back office",
        "Multi-location management",
      ],
      blockImg: "casual-dining-hardware.png",
      ImgAltTag: "fine dining pos",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/fine-dining-pos/demo",
      },
    },
    {
      blockTitle: "SpotOn Serve",
      blockSubTitle:
        " Provide a seamless guest experience and enable your servers to attend to guest needs more efficiently with our intuitive handheld POS devices.",
      blockList: [
        "Full menu and modifier ordering functionality",
        "Send orders to the kitchen and bar in real-time instead of wasting time running back and forth to POS stations.",
        "Pay-at-table with EMV, Apple Pay, and Android Pay card reader",
        "Built-in touchscreen display and thermal printer",
        "Extended range with Wi-Fi and 4G LTE connectivity",
      ],
      blockImg: "rest-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/fine-dining-pos/demo",
      },
    },
    {
      blockTitle: "SpotOn Reserve",
      blockSubTitle:
        "Keep tables full with a complete front-of-house solution that gives you control of your guest data and saves you thousands compared to reservation platforms with per-cover fees.",
      blockImg: "casual-reserve.png",
      ImgAltTag: "fine dining pos",
      blockList: [
        "Reservations & waitlisting",
        "Server & table management",
        "Contact tracing",
        "Two-way table-ready messaging",
        "Guest analytics",
        "Mailing list builder for marketing",
      ],
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/reserve",
      },
    },
    {
      blockTitle: "SpotOn Order",
      blockSubTitle:
        "Delivery and take-out have become an essential part of your success. Online ordering from SpotOn makes ordering and paying easy for your guests while eliminating costly commissions from third-party apps. Let your guests order take-out, delivery, and e-gift cards directly from your website with full POS integration to streamline operations.",
      blockImg: "casual-online-ordering.png",
      ImgAltTag: "fine dining pos",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/online-order-and-delivery",
      },
    },
    {
      blockTitle: "SpotOn Delivery, Powered by DoorDash",
      blockSubTitle:
        "SpotOn Delivery gives you access to the DoorDash delivery network for a low flat fee instead of the costly commissions associated with third-party apps. You also keep your own guest data, so you’re in control of marketing to your guests instead of the competition.",
      blockList: [
        "Guests order directly from your website",
        "Flat rate fee* with the ability to pass costs on to guests",
        "DoorDash handles delivery logistics",
        "You keep your guest data for remarketing",
      ],
      appendix: `*$7.95 per delivery; $9.95 per delivery in California`,
      blockImg: "delivery-b.png",
      ImgAltTag: "fine dining pos",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/fine-dining-pos/demo",
      },
    },
  ],
};

export const fineDiningCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/fine-dining-pos/demo",
  },
};

export const fineDiningVideo = {
  title: "See SpotOn solutions in action",
  videoBtn: "Watch video",
  posterImg: "terminal-poster.png",
  posterWrapperClass: "relative",
  videoUrlId: "y6rUavhKAsk",
};

export const fineDiningTestimonials = {
  title: "What other restaurateurs are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const fineDiningArticles = {
  title: "Expert advice on growing your business",
  bgText: "Articles.",
};

export const fineDiningFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const fineDiningCar = {
  title: "Run a different type of restaurant",
  subtext: "Fine dining restaurants are just one of our specialties.",
  punctuation: "?",
  slides: [
    {
      bg: "quick-dining.png",
      cardTitle: "Quick service restaurant",
      cardContent: "Work faster and smarter",
      icon: "qsr.png",
      linkTo: "/quick-service-pos",
    },
    {
      bg: "casual-dining.png",
      cardTitle: "Casual dining",
      cardContent: "Unlock your restaurant’s potential",
      icon: "casual-dining.png",
      linkTo: "/casual-dining-pos",
    },
    {
      bg: "food-truck.png",
      cardTitle: "Food Trucks",
      cardContent: "Tech that can keep up with your food truck",
      icon: "food-trucks.png",
      linkTo: "/food-truck-pos",
    },
  ],
};
